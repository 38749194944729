import React, { Component } from 'react';
import { graphql, StaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from 'gatsby-image';
import { DialogOverlay, DialogContent } from "@reach/dialog"
import "@reach/dialog/styles.css"
import Masonry from 'react-masonry-css'
import Close from '@assets/close-inline.svg'
import Left from '@assets/left-inline.svg'
import styled from 'styled-components'

import './galeria.scss'

const CloseButton = styled(Close)`
  top: 8px;
  right: 8px;
  position: absolute;
  cursor: pointer;
`

const LeftButton = styled(Left)`
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
  position: absolute;
  cursor: pointer;
`

const RightButton = styled(LeftButton)`
  right: 4px;
  left: auto;
  transform: rotate(180deg);
`

class Galeria extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLightbox: false,
      selectedImage: null,
      currentIndex: 0,
      imagesLength: 0,
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query GaleriaQuery {
            allStrapiGaleria {
              nodes {
                image {
                  id
                  description
                  image {
                    childImageSharp {
                      thumb: fluid(maxWidth: 354) {
                        ...GatsbyImageSharpFluid
                      }
                      full: fluid(maxHeight: 1080) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }  
      `}

      render={(data) => {
        const images = data.allStrapiGaleria.nodes[0].image;

        const { selectedImage, showLightbox } = this.state;
        const breakpointColumnsObj = {
          default: 3,
          768: 3,
          540: 1
        };

        return (
          <Layout>
          <SEO
            title="Galeria - zdjęcia z górskich wycieczek"
            description="Galeria zdjęć z górskich wypraw. Tatry Polskie i Słowackie, Beskidy, Bieszczady. W jednym miejscu najlepsze zdjęcia z gór."
          />
          <div className="content">
            <h1 className="heading">Galeria zdjęć</h1>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="gallery"
                columnClassName="gallery__column">
                  {images.map((item, index) => (
                    <div
                      key={item.id}
                      onClick={() =>
                        this.setState({ showLightbox: true, selectedImage: item, currentIndex: index, imagesLength: images.length })
                      }
                      className="gallery__item">
                        <Img
                          className="gallery__image"
                          fluid={item.image.childImageSharp.thumb}
                          alt={item.description}
                        />
                    </div>
                  ))}
              </Masonry>
            </div>
            {showLightbox && (
              <DialogOverlay
                onClick={() => this.setState({ showLightbox: false })}
              >
                <DialogContent
                  aria-label="Zdjęcie"
                >
                  <Img
                    fluid={selectedImage.image.childImageSharp.full}
                    className={`gallery-image${selectedImage.image.childImageSharp.full.aspectRatio < 1.3 ? ' gallery-image--vertical' : ''}`}
                    alt={selectedImage.description}
                  />
                  <p 
                    aria-label="Opis"
                    className="gallery-description">
                    { selectedImage.description }
                  </p>
                  <CloseButton
                    onClick={() => this.setState({ showLightbox: false })}
                  >
                    Close
                  </CloseButton>
                  {this.state.currentIndex !== 0 && 
                    <LeftButton
                      onClick={() => this.setState({ 
                        selectedImage: images[this.state.currentIndex - 1],
                        currentIndex: this.state.currentIndex - 1,
                      })}
                      onKeyLeft={() => this.setState({ 
                        selectedImage: images[this.state.currentIndex - 1],
                        currentIndex: this.state.currentIndex - 1,
                      })}
                  >
                    Left
                  </LeftButton>
                  }
                  {this.state.currentIndex !== this.state.imagesLength -1 &&
                    <RightButton
                      onClick={() => this.setState({ 
                        selectedImage: images[this.state.currentIndex + 1],
                        currentIndex: this.state.currentIndex + 1,
                      })}
                      onKeyRight={() => this.setState({ 
                        selectedImage: images[this.state.currentIndex + 1],
                        currentIndex: this.state.currentIndex + 1,
                      })}
                    >
                    Right
                  </RightButton>
                  }
                </DialogContent>
              </DialogOverlay>
            )}
        </Layout>
        )}}
      />
    )
  }
}

export default Galeria
